import React, { useState, useEffect } from 'react';
import Detailpart1 from './Detailpart1';
import ChallengeAndSolution from './ChallengeAndSolution';
import Formpart from './Formpart';
import casedata from '../CaseList/casedata.json';
import { useLocation } from 'react-router-dom';

const CaseDetails = () => {
  const [filteredCaseData, setFilteredCaseData] = useState(casedata[0]);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.id) {
      const filteredData = casedata.find((item) => item.id === location.state.id);
      setFilteredCaseData(filteredData);
    }
    else {
      setFilteredCaseData(casedata[0]);
    }
  }, [location.state,casedata.length]);

  return (
    <>
      <Detailpart1 caseData={filteredCaseData} />
      <ChallengeAndSolution casedata={filteredCaseData} />
      {/* <Formpart /> */}
    </>
  )
}

export default CaseDetails
