import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

import PageNotFound from "../pages/PageNotFound";
import Home from "../pages/Home/HomePage";
import App from "../App";
import About from "../pages/About/About";
import CaseList from "../pages/CaseList/CaseList";
import CaseDetails from "../pages/Casedetails/CaseDetails";

import Career from "../pages/Contact/Career";
import Privacy from "../pages/PrivacyPolicy/Privacy";

/* App Router Configuration */
const AppRouter = createBrowserRouter([
  /* Pages */
  {
    path: "",
    element: <App />,
    /* All the pages falls under the APP because we used APP as Root Layout and ALL links and routes configured under the app */
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "policy",
        element: <Privacy />,
      },
      {
        path: "aboutus",
        element: (
          <Suspense
            fallback={
              <>
                <h1>Loading...</h1>
              </>
            }
          >
            <About />
          </Suspense>
        ),
      },
      {
        path: "case-studies",
        element: (
          <Suspense
            fallback={
              <>
                <h1>Loading...</h1>
              </>
            }
          >
            <CaseList />
          </Suspense>
        ),
      },
      {
        path: "caselist/",
        element: (
          <Suspense
            fallback={
              <>
                <h1>Loading...</h1>
              </>
            }
          >
            <CaseList />
          </Suspense>
        ),
      },
      {
        path: "case-detail/:id",
        element: (
          <Suspense
            fallback={
              <>
                <h1>Loading...</h1>
              </>
            }
          >
            <CaseDetails />
          </Suspense>
        ),
      },
      {
        path: "/career",
        element: (
          <Suspense
            fallback={
              <>
                <h1>Loading...</h1>
              </>
            }
          >
            <Career />
          </Suspense>
        ),
      },
    ],
  },

  /* 404 Not found */
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export default AppRouter;
