import React from "react";
const ChallengeAndSolution = ({ casedata }) => {
  console.log("case data", casedata);
  return (
    <>
      {/* <img
        style={{
          width: "101%",
          height: "27rem",
          margin: "63px 0",
          padding: "0 100px",
        }}
        src="/images/ff.png"
        alt=""
      /> */}

      <div className="cands">
        <h2>Feature</h2>
        <div className="feature">
          {/* <img src="\images\F1.jpeg" alt="" /> */}
          <img src="\images\d1 (2).png" alt="" />
          <div className="data1">
            {casedata?.Feature?.map((item, index) => (
              <ul key={index}>
                <li key={index}>{item}</li>
              </ul>
            ))}
          </div>
        </div>

        <h2>Challenge</h2>
        <div className="challenge">
          <img src="/images/d2.png" alt="" />
          <div className="data2">
            {casedata?.listchallenge?.map((item, index) => (
              <ul key={index}>
                <li key={index}>{item}</li>
              </ul>
            ))}
          </div>
        </div>
        <h2>Solution</h2>
        <div className="solution">
          <img style={{ height: "50%" }} src="\images\image.png" alt="" />
          <div>
            <p>
              We implemented a cutting-edge cognitive search and knowledge
              discovery platform powered by LLMs. Our NLP expertise helped the
              client achieve lightning-fast content access, enhanced research
              experience, and bias-free, inclusive communication.
            </p>
            <div className="data3">
              {casedata?.listsolution?.map((item, index) => (
                <ul key={index}>{<li key={index}>{item}</li>}</ul>
              ))}{" "}
            </div>
          </div>
        </div>
        {!!casedata?.resultlist?.length && (
          <>
            <h2>Result</h2>
            <div className="Result">
              <img src="\images\d3.png" alt="" />
              <div className="data4">
                {casedata?.resultlist?.map((item, index) => (
                  <ul key={index}>{<li key={index}>{item}</li>}</ul>
                ))}
              </div>
            </div>
          </>
        )}

        {!!casedata?.resultlist?.length && (
          <>
            <h2>Conclusion</h2>
            <div className="Conclusion">
              <img src="\images\d4.png" alt="" />

              <div className="data5">
                {casedata?.conclusion?.map((item, index) => (
                  <ul key={index}>{<li key={index}>{item}</li>}</ul>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default ChallengeAndSolution;
