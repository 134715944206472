import React, { useState } from "react";
import Contact from "./Contact";
import { useActionData } from "react-router-dom";

const JoinRm = ({ handle }) => {
  return (
    <>
      <div className="flexing">
        <div className="joinrm">
          <h1>Be a Part of the Story of</h1>
          <h1>Cutting-Edge Digital Products</h1>
          <p>
            Join an amazing team of engineers, innovators, and thinkers with
            endless opportunities for you to learn, grow, and thrive.
          </p>
          <div className="joinrmbricksbtn">
            <button onClick={handle}>Join Remotebricks Family</button>
          </div>
        </div>
        <div className="imgpyramid">
          <img
            src="https://www.simform.com/wp-content/uploads/2024/12/careers-hero-image.svg"
            alt=""
          />
        </div>
      </div>

      {/* <div className="ratingpart">
        <h3>
          Our company culture is Amazing and our Different platform Reviews
          proves it.
        </h3>
        <div className="imagerate">
          <img
            src="https://www.simform.com/wp-content/uploads/2024/12/careers-review-img.svg"
            alt=""
          />
        </div>
      </div> */}
    </>
  );
};

export default JoinRm;
