import { useState } from "react";
import Caseoption from "./Caseoption";
import Case from "./Case";
import casedata from "./casedata.json";
import industrylist from "./industrylist.json";

const industryList = industrylist;

const CaseList = () => {
  const [caseList, setCaseList] = useState(casedata);

  const filteCaseList = (industryType: string) => {
    if (industryList.includes(industryType)) {
      const filtereredList = casedata.filter(
        (item) => item.industry === industryType
      );
      setCaseList(filtereredList);
    } else {
      setCaseList(casedata);
    }
  };

  return (
    <>
      {/* <Caseoption arr={industryList} filterCaseList={filteCaseList} /> */}
      <Case caseList={caseList} />
    </>
  );
};

export default CaseList;
