import React from "react";
import { useNavigate } from "react-router-dom";

const Case = (props: any) => {
  let navigate = useNavigate();
  const { caseList } = props;
  function handlecaselist(currentValue: any) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/case-detail/" + currentValue.id, {
      state: {
        id: currentValue.id,
      },
    });
  }

  return (
    <>
      {/* <section className='sectionpart2'>
        {
          caseList.map((currentValue:any,index:number) => (
            <div className='box' key={index} onClick={() => {
              handlecaselist(currentValue);
            }}> 
              <div className='inner-container'>
               <div className='img-container'>
                  <img src={currentValue.img} alt={currentValue.tittle} className='case-image' />
                </div> 
                <button className='category-button'>{currentValue.industry}</button>
                <h2 className='tittle'style={{opacity:"1"}} >{currentValue.title}</h2>
                <p className='subtitle'>{currentValue.subtitle}</p> 
                <button className='read-more' >{currentValue.Read}</button>
              </div>
            </div>
          ))
        }
        
      </section > */}

      <div className="sectionpart2">
        {caseList.map((currentValue: any, index: number) => (
          <div
            className="card"
            key={index}
            style={{ height: "450px" }}
            onClick={() => {
              handlecaselist(currentValue);
            }}
          >
            <img
              src={currentValue.img}
              alt="Background"
              className="card__image"
            />
            <div className="card__content">
              <h3
                className="card__title"
                style={{
                  fontFamily: "Poppins-Medium,sans-serif",
                  fontWeight: "400",
                }}
              >
                {currentValue.title}
              </h3>
            </div>
            <div className="cardlink">
              <div className="tags">
                {currentValue.tags.map((tag: string, index: number) => (
                  <h5
                    style={{ fontFamily: "Poppins-Medium, sans-serif" }}
                    key={index}
                  >
                    #{tag}
                  </h5>
                ))}
              </div>
              <a
                href="#"
                className="cardlink"
                key={index}
                onClick={() => {
                  handlecaselist(currentValue);
                }}
                style={{ color: "white", textShadow: "20px" }}
              >
                Read full story →
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Case;
