import React from "react";

const Detailpart1 = ({ caseData }) => {
  if (!caseData) {
    return <div>Loading case details...</div>;
  }

  return (
    <div className="detailspart">
      <div className="firstwhole">
        <div className="detailbox1">
          <div className="datadetails">
            <h1>{caseData.title}</h1>
            <p className="parasubtittle"> {caseData.subtitle}</p>
          </div>
        </div>
        {/* <div className="imgpart" >
          <img src={caseData.img} alt={caseData.title} style={{width:"100%"}} />
        </div> */}
      </div>
      <div className="statspart">
        {/* <div className="part1">
          <h1> 80%</h1>
          <p>Increased productivity</p>
        </div>
        <div className="part1">
          <h1> 80%</h1>
          <p>Improved efficiency</p>
        </div>
        <div className="part1">
          <h1> 80%</h1>
               <p>improved Effeciency</p>
        </div> */}
      </div>
    </div>
  );
};

export default Detailpart1;
