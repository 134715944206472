import React from "react";
import "./PrivacyPolicy.scss";

const Privacy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>
        Welcome to Remotebricks ("we," "our," "us"). Your privacy is important
        to us. This Privacy Policy explains how we collect, use, and protect
        your information when you visit our website.
      </p>

      <h2>1. Information We Collect</h2>
      <ul>
        <li>
          <strong>Personal Information:</strong> When you contact us, we may
          collect your name, email, phone number, and payment details.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you interact with
          our website, including pages visited, time spent, and device/browser
          details.
        </li>
        <li>
          <strong>Cookies and Tracking Technologies:</strong> We use cookies to
          enhance your experience, analyze site traffic, and personalize
          content.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <ul>
        <li>Provide and improve our services</li>
        <li>Process transactions and support requests</li>
        <li>Analyze and enhance website performance</li>
        <li>Send marketing and promotional messages (with your consent)</li>
        <li>Ensure security and prevent fraud</li>
      </ul>

      <h2>3. Cookies and Tracking Technologies</h2>
      <p>
        Cookies are small files stored on your device that help us recognize you
        and improve your browsing experience.
      </p>
      <ul>
        <li>
          <strong>Essential Cookies:</strong> Necessary for website
          functionality.
        </li>
        <li>
          <strong>Analytical Cookies:</strong> Help us analyze site performance.
        </li>
        <li>
          <strong>Marketing Cookies:</strong> Used for advertising and
          retargeting.
        </li>
      </ul>
      <p>You can manage or disable cookies through your browser settings.</p>

      <h2>4. Data Sharing and Third-Party Services</h2>
      <p>
        We do not sell your personal information. However, we may share data
        with:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> Trusted third parties that assist
          in website operations.
        </li>
        <li>
          <strong>Analytics Providers:</strong> Such as Google Analytics to
          track website traffic.
        </li>
        <li>
          <strong>Legal Authorities:</strong> If required by law or to protect
          our rights.
        </li>
      </ul>

      <h2>5. Data Security</h2>
      <p>
        We implement industry-standard security measures to protect your
        information. However, no method of transmission over the Internet is
        100% secure.
      </p>

      <h2>6. Your Rights and Choices</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access, update, or delete your personal information.</li>
        <li>Opt out of marketing communications.</li>
        <li>Manage cookies through your browser settings.</li>
      </ul>
      <p>
        To exercise your rights, please contact us at{" "}
        <a href="mailto:Contact@remotebricks.com">Contact@remotebricks.com</a>.
      </p>

      <h2>7. Changes to This Policy</h2>
      <p>
        We may update this Privacy Policy periodically. Any changes will be
        posted on this page with an updated effective date.
      </p>

      <h2>8. Contact Us</h2>
      <p>If you have any questions, please contact us at:</p>
      <p>
        Email:{" "}
        <a href="mailto:Contact@remotebricks.com">Contact@remotebricks.com</a>
      </p>
      <p>
        Address: 617, 6th Floor, Business Hub, Arved Transcube Plaza, 132 Feet
        Ring Road, Ranip, Ahmedabad - 382480 (Gsrtc Busstand)
      </p>
      <p>
        Thank you for trusting <b>RemoteBricks </b> with your information!
      </p>
    </div>
  );
};

export default Privacy;
