// React component with drag and drop input and SCSS styling

import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";


const Contact = () => {
  const validationSchema = Yup.object({
    firstname: Yup.string().required("Required"),
    lastname: Yup.string().required("Required"),
    Email: Yup.string().email("Invalid email address").required("Required"),
    PhoneNumber: Yup.string().required("Required"),
    Resume: Yup.mixed().required("Resume is required"),
  });

  return (
    <div className="formpart">
      <div className="img-part">
        <img src="../imges/image.png" alt="RemoteBricks Logo"  height="300px"/>
      </div>

      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          Email: "",
          PhoneNumber: "",
          Resume: null,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="inputpart">
              <Field type="text" name="firstname" placeholder="First Name" />
              <ErrorMessage name="firstname" component="div" className="error-message" />
            </div>

            <div className="inputpart">
              <Field type="text" name="lastname" placeholder="Last Name" />
              <ErrorMessage name="lastname" component="div" className="error-message" />
            </div>

            <div className="inputpart">
              <Field type="email" name="Email" placeholder="Email" />
              <ErrorMessage name="Email" component="div" className="error-message" />
            </div>

            <div className="inputpart">
              <Field type="text" name="PhoneNumber" placeholder="Phone Number" />
              <ErrorMessage name="PhoneNumber" component="div" className="error-message" />
            </div>

            <div
              className="drag-drop-area"
              onClick={() => document.getElementById("fileUpload").click()}
            >
              <p>Drag & drop your resume here or click to upload
                <img src="https://icon-library.com/images/drag-drop-icon/drag-drop-icon-7.jpg" alt=""  height="15px" style={{marginTop:"20px"}}/>
              </p>
              <input
                id="fileUpload"
                name="Resume"
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={(event) => setFieldValue("Resume", event.currentTarget.files[0])}
                className="hidden-file-input"
              />
            </div>
            <ErrorMessage name="Resume" component="div" className="error-message" />

            <button type="submit" className="submit-btn">
              Submit
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Contact;
