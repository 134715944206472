import React from "react";
import EarthCanvas from "../../components/EarthCanvas";
import { useNavigate } from "react-router-dom";




const Banner = () => {
//   let navigate = useNavigate();
//   const handbtn=()=>{
// navigate("/caselist/")
//   }
  return (
    <section className="section banner-section h-100">
      <div className="container-fluid">
        <EarthCanvas />
        <div className="tag-line-container">
          <h1 className="tag-line text-gradient">
            <span className="fw-sbo">Connecting talents</span> Globally,
            <br /> Delivering Innovation
            <span className="fw-sbo ms-2">Remotely</span>
          </h1>
          {/* <div className="btns-for-meetingandcs">
                  <button id="button-for-casestudies" onClick={handbtn} style={{backgroundColor:"#000033", border:"1px solid navy"}}>Case Studies</button>
                <a
                href="https://calendly.com/contact-remotebricks/30min?month=2025-03"
                target="_blank"
                  >
                <button id="button-for-casestudies" style={{width:"15vw", borderBottom:"1px solid navy"}}>Book meeting </button>
                  </a>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Banner;
