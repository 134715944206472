import React from "react";
import { ReactComponent as RemoteBricksAnimationLogo } from "../../assets/images/RemotebricksLoading/animation-logo.svg";
import { ReactComponent as AWSIcon } from "../../assets/Techs/AWS.svg";
import { ReactComponent as AngularIcon } from "../../assets/Techs/Angular.svg";
import { ReactComponent as CPPIcon } from "../../assets/Techs/CPP.svg";
import { ReactComponent as DOCKERIcon } from "../../assets/Techs/DOCKER.svg";
import { ReactComponent as ElectronIcon } from "../../assets/Techs/Electron.svg";
import { ReactComponent as FlutterIcon } from "../../assets/Techs/Flutter.svg";
import { ReactComponent as GSAPIcon } from "../../assets/Techs/GSAP.svg";
import { ReactComponent as JSIcon } from "../../assets/Techs/JS.svg";
import { ReactComponent as JavaIcon } from "../../assets/Techs/Java.svg";
import { ReactComponent as MongoDBIcon } from "../../assets/Techs/MongoDB.svg";
import { ReactComponent as NODEIcon } from "../../assets/Techs/NODE.svg";
import { ReactComponent as NestJSIcon } from "../../assets/Techs/NestJS.svg";
import { ReactComponent as NextJSIcon } from "../../assets/Techs/NextJS.svg";
import { ReactComponent as PythonIcon } from "../../assets/Techs/Python.svg";
import { ReactComponent as ReactIcon } from "../../assets/Techs/React.svg";
import { ReactComponent as SeleniumIcon } from "../../assets/Techs/Selenium.svg";
import { ReactComponent as ShopyfyIcon } from "../../assets/Techs/Shopyfy.svg";
import { ReactComponent as TauriJSIcon } from "../../assets/Techs/TauriJS.svg";
import { ReactComponent as VUEJSIcon } from "../../assets/Techs/VUEJS.svg";
import { ReactComponent as RMBCKS } from "../../assets/images/RMBTECHS.svg";

const TechStack = () => {
  return (
    <>
    {/* <section className="section tech-stack-section">
      <div className="container tech-stack-container">
        <div className="tech-stacks">
          <div className="row">
            <div className="col-md-12">
              <div className="tech-stack-description text-center mb-5">
                <div className="section-heading text-center mb-5">
                  <h5 className="fw-reg mb-2">Our</h5>
                  <h3 className="fw-sbo">Tech Stack</h3>
                </div>
                <p className="fw-reg">
                  At Remote Bricks, we're expanding our tech stack to build more
                  robust, scalable, and efficient solutions. On the front end,
                  we leverage modern frameworks like React, Angular, and Vue.js,
                  ensuring fast, responsive, and user-friendly interfaces. For
                  the backend, we're deepening our use of Node.js, Python
                  (Django/Flask), and Java (Spring Boot), supported by robust
                  databases like PostgreSQL, MongoDB, and MySQL. Additionally,
                  our QA processes are powered by cutting-edge automation tools
                  such as Selenium, Cypress, and Jest, ensuring our software
                  meets the highest standards of quality and performance across
                  all platforms.
                </p>
              </div>

              <div className="tech-stack-thumb">
                <div className="tech-perspective">
                  <div className="path-wrapper">
                    <RemoteBricksAnimationLogo className="animation-logo" />
                    <RMBCKS className="circle-paths" />
                    <div className="animateTechs">
                      <div className="tech-svgs">
                        <AWSIcon className="svg" />
                        <AngularIcon className="svg" />
                        <CPPIcon className="svg" />
                        <DOCKERIcon className="svg" />
                        <ElectronIcon className="svg" />
                        <FlutterIcon className="svg" />
                        <GSAPIcon className="svg" />
                        <JSIcon className="svg" />
                        <JavaIcon className="svg" />
                        <MongoDBIcon className="svg" />
                        <NODEIcon className="svg" />
                        <NestJSIcon className="svg" />
                        <NextJSIcon className="svg" />
                        <PythonIcon className="svg" />
                        <ReactIcon className="svg" />
                        <SeleniumIcon className="svg" />
                        <ShopyfyIcon className="svg" />
                        <TauriJSIcon className="svg" />
                        <VUEJSIcon className="svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      <div className="tech-stack" style={{ display: "flex", justifyContent: "center",flexDirection:"column" }}>
      <h5 className="fw-reg mb-2">Our</h5>
      <h3 className="fw-sbo">Tech Stack</h3>
        <div className="tech-stack-container">
      {/* <div className="section-heading">
                  <h5 className="fw-reg mb-2">Our</h5>
                  <h3 className="fw-sbo">Tech Stack</h3>
        </div>
        <div className="techpara">
        <p className="fw-reg">
                  At Remote Bricks, we're expanding our tech stack to build more
                  robust, scalable, and efficient solutions. On the front end,
                  we leverage modern frameworks like React, Angular, and Vue.js,
                  ensuring fast, responsive, and user-friendly interfaces. For
                  the backend, we're deepening our use of Node.js, Python
                  (Django/Flask), and Java (Spring Boot), supported by robust
                  databases like PostgreSQL, MongoDB, and MySQL. Additionally,
                  our QA processes are powered by cutting-edge automation tools
                  such as Selenium, Cypress, and Jest, ensuring our software
                  meets the highest standards of quality and performance across
                  all platforms.
                </p>
          </div> */}
          </div>
        
        <div className="tech-svgs" style={{display:"grid",gap:"30px"}} >
          <div className="icon">
          <AWSIcon className="svg"  width="80px" />
          </div>
          <div className="icon">
          <AngularIcon className="svg"  width="80px" />
          </div>
          <div className="icon">
          <CPPIcon className="svg"   width="80px"/>
          </div>
          <div className="icon">
          <DOCKERIcon className="svg"   width="80px"/>
          </div> <div className="icon">
          <ElectronIcon className="svg"   width="80px"/>
          </div> <div className="icon">
          <FlutterIcon className="svg"   width="80px"/>
          </div> <div className="icon">
          <GSAPIcon className="svg"   width="80px"/>
          </div> <div className="icon">
          <JSIcon className="svg"   width="80px"/>
          </div> <div className="icon">
          <JavaIcon className="svg"   width="80px"/>
          </div> <div className="icon">
          <MongoDBIcon className="svg"   width="80px"/>
          </div> <div className="icon">
          <NODEIcon className="svg"   width="80px"/>
          </div> <div className="icon">
          <NestJSIcon className="svg"   width="80px"/>
          </div> <div className="icon">
          <NextJSIcon className="svg"   width="80px"/>
          </div> <div className="icon">
          <PythonIcon className="svg"   width="80px"/>
          </div>
          <div className="icon">
          <SeleniumIcon className="svg"   width="80px"/>
          </div>
          <div className="icon">
          <ShopyfyIcon className="svg"   width="80px"/>
          </div>
          <div className="icon">
          <TauriJSIcon className="svg"   width="80px"/>
          </div>
          <div className="icon">
          <VUEJSIcon className="svg"   width="80px"/>
          </div>
         
         </div>
      </div> 
    {/* </section> */}
    </>
  );
};

export default TechStack;
