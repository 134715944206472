import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaHome, FaBook,FaBookReader } from 'react-icons/fa';
import { LuRefreshCcw } from "react-icons/lu";
import { color, motion } from "framer-motion";
import "react-tabs/style/react-tabs.css";

const Tabing = () => {
    const data = [
        {
            "icon": <LuRefreshCcw />, "title": "Flexible for Work-life balance",
            "description": "Stay inspired with flexible timings, work hours, and leave policies that ensure seamless work-life balance."
        },
        {
            "icon": <FaBook />, "title": "Reward & recognition",
            "description": "Receive appreciation from clients, managers, and peers, fostering a positive work culture. Earn rewards like points, gift cards, or experiences based on recognition."
        },
        {
            "icon": <FaHome />, "title": "Work From Home Policy",
            "description": "You can work from home when required, with approval from your manager, offering flexibility in your work routine."
        },
        {
            "icon": <FaBookReader />, "title": "No Leave Policy",
            "description":"We do not have a formal leave policy in place. However, we trust our team to manage their time responsibly while ensuring that work commitments are met. If anyone needs time off, they simply need to inform in advance so that work can be planned accordingly. We do not deduct salaries for leave, as we believe in flexibility and a healthy work-life balance."
        }
    ];

    const animationVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
    };

    return (
        <Tabs>
            <TabList className="tab">
                <Tab className="tabcolor">Who we are</Tab>
                <Tab className="tabcolor">Benefits we offer</Tab>
                <Tab className="tabcolor">Hiring Process</Tab>
            </TabList>

            <TabPanel>
                <motion.div className="tabcontent1" initial="hidden" animate="visible" variants={animationVariants}>
                <div className="tabcontent1">
                            <p>At RemoteBricks, we’re passionate about harnessing the power of technology to drive business success. Established with a vision to revolutionize the digital landscape, our company stands at the forefront of innovation, dedicated to empowering businesses through cutting-edge solutions and unparalleled expertise.</p>
                            <p>
                                Our team of skilled professionals is committed to fostering growth and transformation. We believe that technology should be a catalyst for progress, not a barrier. By combining deep industry knowledge with a forward-thinking approach, we help our clients navigate the complexities of the digital world with confidence.
                            </p>
                            <p>
                                At Remotebricks, we follow a structured process to deliver high-quality, bug-free solutions. We start by brainstorming and gathering requirements, then create Figma prototypes for visualization. After estimating timelines, we move to development and deploy in a staging environment, where our QA team conducts automation, manual, and contact testing. Once validated, we ensure a smooth zero-bug production release. Post-launch, we provide continuous support and maintenance.
                            </p>
                            <p>
                                We have successfully delivered 30+ projects across diverse domains, including healthcare, fintech, e-commerce, education, travel, and transport.Our processes align with ISO 9000 standards, ensuring quality and compliance in every project.
                            </p>
                        </div>
                </motion.div>
            </TabPanel>

            <TabPanel>
                <motion.div className="benefit" initial="hidden" animate="visible" variants={animationVariants}>
                    {data.map((item, index) => (
                        <div className="benefit-part" key={index}>
                            <div className="benefit-image">
                                <h1>{item.icon}</h1>
                            </div>
                            <div className="desc">
                                <h5>{item.title}</h5>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </motion.div>
            </TabPanel>

            <TabPanel>
                <motion.div className="hiring-part" initial="hidden" animate="visible" variants={animationVariants}>
                   

  
                            <h3>How we hire</h3>
                            <p>Our recruitment and hiring team is dedicated to ensuring you have a positive experience throughout the hiring process. Each step is thoughtfully designed to help you feel comfortable while enabling us to make fair and timely decisions.</p>
                            <div className="hiring-process">
                                <div className="hiring-step">
                                    <div className="div">

                                        <h5> 1. Application</h5>
                                        <p>Find the current opening on the career page and apply for a role that aligns with your skills and interests. One of our recruiters will reach out to you.</p>
                                    </div>
                                </div>
                                <div className="hiring-step">
                                    <div className="div">

                                        <h5> 2. Candidate Screening</h5>
                                        <p>Our recruiters will schedule a call to discuss your background, experience, and career goals to make sure you’re a good fit for the role. This is also an opportunity for you to learn more about us and the role.</p>
                                    </div>
                                </div>
                                <div className="hiring-step">
                                    <div className="div">

                                        <h5> 3.Teachnical Round</h5>
                                        <p>Depending on the role, you may complete a test task or participate in a technical interview with one of our engineers to demonstrate your expertise.</p>
                                    </div>
                                </div>
                                <div className="hiring-step">
                                    <div className="div">

                                        <h5> 4.Leadership Interview</h5>
                                        <p>In this round, a hiring manager or senior engineering manager will assess your ability to drive projects, make decisions, solve problems, and navigate team dynamics. It’s also an opportunity to discuss how your skills and experience can contribute to the team’s succesS</p>
                                    </div>
                                </div>
                         
                                <div className="hiring-step">
                                    <div className="div">

                                        <h5> 6.Onboardiing</h5>
                                        <p>Once you accept the offer, our onboarding process will help you seamlessly integrate into your new role. This includes HR onboarding, IT onboarding, and role-specific onboarding, ensuring  fully equipped and prepared for your new role.</p>
                                    </div>
                                </div>

                            </div>
                       
                </motion.div>
            </TabPanel>
        </Tabs>
    );
};

export default Tabing;

