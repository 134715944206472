import React, { useState } from "react";
import RemoteBricksLogo from "../assets/images/RemoteBricksLogos/RemoteBricksLogo-light.svg";
import { ReactComponent as LinkedInIcon } from "../assets/icons/LinkedIn.svg";
import { ReactComponent as ClutchIcon } from "../assets/icons/Clutch.svg";
import { ReactComponent as MediumIcon } from "../assets/icons/Medium.svg";
import { ReactComponent as InstagramIcon } from "../assets/icons/Instagram.svg";
import GetInTouchDialogue from "./GetInTouchDialogue";
import { Link } from "react-router-dom";

const Footer = () => {
  const [isShowContactUs, setContactUsVisible] = useState<boolean>(false);

  return (
    <footer className="main-footer">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12">
            <div className="brand-logo-container">
              <img
                src={RemoteBricksLogo}
                className="brand-logo"
                alt="Remotebricks Logo"
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="contacts-wrapper">
              <h6 className="fw-med">Contacts</h6>
              <hr
                style={{
                  border: "1px solid #23294b",
                  opacity: "1",
                  width: "80%",
                }}
              />

              <ul>
                <li>
                  <a href="mailto: contact@remotebricks.com">
                    contact@remotebricks.com
                  </a>
                </li>
                <li>
                  <a href="mailto: info@remotebricks.com">
                    info@remotebricks.com
                  </a>
                </li>
                <li>
                  <a href="tel: 080-378 62368">080-378 62368</a>
                </li>
                <li>
                  <a href="tel: 087-489 06886">087-489 06886</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="headquarters-address-wrapper">
              <h6 className="fw-med">Headquarters</h6>
              <hr
                style={{
                  border: "1px solid #23294b",
                  opacity: "1",
                  width: "80%",
                }}
              />
              <p>
                WeWork Prestige Atlanta, 80 Feet Main Road,
                <br />
                Koramangala,
                <br />
                1A Block Bangalore, KA – 560034
              </p>
            </div>
            <div className="col-lg-4">
              <div className="social-media-wrapper">
                {/* <h6 className="fw-med">Social Media</h6>
                <hr  style={{border:"1px solid #23294b", opacity:"1",width:"300px "}}/> */}
                <ul className="d-flex mb-0 gap-4 justify-content-start">
                  <li className="mb-0">
                    <a
                      href="https://in.linkedin.com/company/remotebricks"
                      target="_blank"
                    >
                      <LinkedInIcon height={30} width={30} />
                    </a>
                  </li>
                  <li className="mb-0">
                    <a
                      href="https://clutch.co/profile/remotebricks#highlights"
                      target="_blank"
                    >
                      <ClutchIcon height={20} width={20} />
                    </a>
                  </li>
                  <li className="mb-0">
                    <a
                      href="https://www.instagram.com/rmtbricks/"
                      target="_blank"
                    >
                      <InstagramIcon height={30} width={30} />
                    </a>
                  </li>
                  <li className="mb-0">
                    <a href="">
                      <MediumIcon height={30} width={30} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="meeting">
              <h6
                style={{
                  fontFamily: "Poppins-Medium,sans-serif",
                  fontWeight: "200",
                }}
              >
                Schedule a Meeting
              </h6>
              <hr
                style={{
                  border: "1px solid #23294b",
                  opacity: "1",
                  width: "80%",
                }}
              />
              {/* <div className="img-part">
                <img
                  src="https://t4.ftcdn.net/jpg/09/70/21/89/360_F_970218906_CNYqVPcOwKAq1IMEemMcUB2XFhcmsFih.jpg"
                  alt="meeting"
                  height="80px"
                  width="80px"
                  style={{ borderRadius: "50%" }}
                />
              </div> */}
              <h5 style={{ border: "none" }}>Khusbhu Jain</h5>
              <p style={{ maxWidth: "330px" }}>
                Creating a tech product roadmap and building scalable apps for
                your organization.
              </p>

              <a
                href="https://calendly.com/contact-remotebricks/30min?month=2025-03"
                target="_blank"
              >
                <button className="button">Book meeting </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="brand-logo">
        <img src="http://localhost:3000/static/media/RemoteBricksLogo-light.460da1f598a4790565a3eb3eb27626d2.svg" alt=""  style={{marginLeft:"6%" ,height:"50px"}}/>
</div>
      <div className="footer-part">
        <div className="contact">
          <h6>Contacts</h6>
      <ul>
                <li>
                  <a href="mailto: contact@remotebricks.com">
                    contact@remotebricks.com
                  </a>
                </li>
                <li>
                  <a href="mailto: info@remotebricks.com">
                    info@remotebricks.com
                  </a>
                </li>
                <li>
                  <a href="tel: 080-378 62368">080-378 62368</a>
                </li>
                <li>
                  <a href="tel: 087-489 06886">087-489 06886</a>
                </li>
              </ul>
        </div>

        <div className="headquater">
          <h6>Headquarter</h6>
          <p>
                WeWork Prestige Atlanta, 80 Feet Main Road,
                <br />
                Koramangala,
                <br />
                1A Block Bangalore, KA – 560034
              </p>
        </div>
        
        <div className="social">
      
              <h6 >Social Media</h6>
              <ul  className="icons">
                <li> 
                  <a
                    href="https://in.linkedin.com/company/remotebricks"
                    target="_blank"
                  >
                    <LinkedInIcon height={30} width={30} />
                  </a>
                </li>
                <li >
                  <a
                    href="https://clutch.co/profile/remotebricks#highlights"
                    target="_blank"
                  >
                    <ClutchIcon height={20} width={20} />
                  </a>
                </li>
                <li >
                  <a
                    href="https://www.instagram.com/rmtbricks/"
                    target="_blank"
                  >
                    <InstagramIcon height={30} width={30} />
                  </a>
                </li>
                <li >
                  <a href="">
                    <MediumIcon height={30} width={30} />
                  </a>
                </li>
              </ul>
        </div>
        
        <div className="meetingbook">
        <h6>Join Remotebricks</h6>
          <div className="img-part">
            <img src="https://t4.ftcdn.net/jpg/09/70/21/89/360_F_970218906_CNYqVPcOwKAq1IMEemMcUB2XFhcmsFih.jpg" alt="meeting" height="80px" width="80px"  style={{borderRadius:"50%"}}/>
          </div>
          <h5>Khusbhu Jain</h5>
        <p style={{maxWidth:"330px"}}>
          Creating a tech product roadmap and building scalable apps for your
          organization.
        </p>
        
          
          <a href="https://calendly.com/contact-remotebricks/30min?month=2025-03" target="_blank" >
              <button  className="button">book a meeting </button>
              </a>
         
             </div>
          </div> */}

      <div className="privacy-statment">
        <div className="container">
          <div className="py-sm-4 py-3">
            <div className="row g-2">
              <div className="col-lg-6">
                <div className="copyrights">
                  <p className="mb-0">
                    © 2024-2025 Remotebricks. All rights reserved.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <ul className="d-flex my-0 gap-4 justify-content-sm-end justify-content-center">
                  <li className="mb-0">
                    <Link to="/policy">Privacy policy</Link>
                  </li>
                  <li className="mb-0">
                    <p onClick={() => setContactUsVisible(true)}>
                      Customer support
                    </p>
                  </li>

                  <li className="mb-0">
                    <Link to="/career">Careers</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowContactUs && (
        <GetInTouchDialogue
          onclose={() => setContactUsVisible(false)}
        ></GetInTouchDialogue>
      )}
    </footer>
  );
};

export default Footer;
