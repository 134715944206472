import React from 'react'
import JoinRm from './JoinRm'
import Tabsi from './Tabs'
import Contact from './Contact'
import { useState } from 'react'
import { Tabs } from 'react-tabs'



const Career = () => {
  
  const handle=()=>{
   let scroll = document.querySelector('.career');
    scroll.scrollIntoView({ behavior: 'smooth' });
    
   }
 
  
  return (
      <>
         
          <JoinRm handle={handle}  />
        <Tabsi/>
      <div className="career">
        <Contact />
      </div>
          
         
      </>
  )
}

export default Career
